import VideoPlayer from "./VideoPlayer";
import VideoList from "./VideoList";

const tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
const firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const VIDEOS = {};

const videoElements = {
  main: ".video_container",
  header: ".header_video",
  modal: ".video-modal-container",
};

const initializeVideos = () => {
  Object.keys(videoElements).forEach((videoLocation) => {
    const videoElement = Array.from(
      document.querySelectorAll(videoElements[videoLocation])
    );
    if (videoElement.length === 0) return;
    VIDEOS[videoLocation] = {};
    videoElement.forEach((element) => {
      const videoId = element.dataset.clip;
      const videoProvider = element.dataset.provider;
      const Strategy = VideoPlayer.types()[videoProvider];
      const player = new Strategy(videoId, videoLocation);
      const videoPlayer = new VideoPlayer(player);
      VIDEOS[videoLocation][videoId] = videoPlayer;
    });
  });
  window.videoList = new VideoList(VIDEOS);
};

window.onYouTubeIframeAPIReady = () => {
  if (document.readyState !== "loading") {
    initializeVideos();
  } else {
    document.addEventListener("DOMContentLoaded", initializeVideos);
  }
};
