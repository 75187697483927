import YouTubePlayer from "./YoutubePlayer";
import VimeoPlayer from "./VimeoPlayer";

class VideoPlayer {
  constructor(player) {
    Object.assign(this, player);
  }

  static types() {
    return {
      YouTube: YouTubePlayer,
      Vimeo: VimeoPlayer,
    };
  }
}

export default VideoPlayer;
