class VideoList {
  constructor(videos) {
    this.videos = videos;
  }

  play(videoLocation, videoId, videoLocationToBeReplaced = null) {
    const videoToPlay = this.videos[videoLocation][videoId];

    if (videoLocationToBeReplaced === null) {
      videoToPlay.play();
      return;
    }

    const videoToStop = this.videos[videoLocationToBeReplaced][videoId];

    videoToStop.stop().then((currentTime) => {
      videoToPlay.play(currentTime);
    });
  }

  stop(videoLocation, videoId) {
    const videoToStop = this.videos[videoLocation][videoId];

    videoToStop.stop().then((currentTime) => {
      Object.keys(this.videos).forEach((videoLoc) => {
        const videoPlayer = this.videos[videoLoc][videoId];
        videoPlayer.setCurrentTime(currentTime);
      });
    });
  }
}

export default VideoList;
