class VimeoPlayer {
  constructor(videoId, videoLocation) {
    let element = `${videoLocation}_${videoId}`;
    let width = 600;
    let height = 400;

    if (videoLocation === "header") {
      width = 500;
      height = 281;
    }

    this.player = new Vimeo.Player(element, {
      id: videoId,
      width,
      height,
      muted: false,
    });

    this.player.ready().then(() => {
      const elementDom = document.getElementById(`${element}`);
      if (elementDom.closest(".video-modal-container") === null) {
        elementDom
          .querySelector("iframe")
          .classList.add("responsive_padding", "iframe", "vimeo-video");
      }
    });

    // current time is set to 0.001 instead of 0 so initial play of Vimeo video will work
    this.currentTime = 0.001;
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.setCurrentTime = this.setCurrentTime.bind(this);
  }

  setCurrentTime(currentTime) {
    this.currentTime = currentTime;
  }

  play(currentTime) {
    // if currentTime is 0 or null, then check if we have a stored currentTime
    currentTime = currentTime || this.currentTime;
    this.player.setCurrentTime(currentTime).then(() => {
      this.player
        .play()
        .then(() => {
          console.log("Vimeo video was played");
        })
        .catch((error) => {
          if (Rollbar === undefined) {
            console.log(
              "Rollbar is not defined",
              "VimeoPlayer play function error",
              error
            );
            return;
          }

          Rollbar.error("VimeoPlayer play function error", error);
        });
    });
  }

  stop() {
    this.player
      .pause()
      .then(() => {
        console.log("Vimeo video was stopped");
        this.player.getCurrentTime().then((currentTime) => {
          this.setCurrentTime(currentTime);
        });
      })
      .catch((error) => {
        if (Rollbar === undefined) {
          console.log(
            "Rollbar is not defined",
            "VimeoPlayer stop function error",
            error
          );
          return;
        }

        Rollbar.error("VimeoPlayer stop function error", error);
      });

    return this.player.getCurrentTime();
  }
}

export default VimeoPlayer;
