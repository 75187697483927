class YoutubePlayer {
  constructor(videoId, videoLocation) {
    const element = `${videoLocation}_${videoId}`;
    this.currentTime = 0;
    this.playerReady = false;
    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.setCurrentTime = this.setCurrentTime.bind(this);

    this.player = new YT.Player(element, {
      height: "400",
      width: "600",
      videoId,
      events: {
        onReady: this.onPlayerReady,
      },
    });
  }

  onPlayerReady() {
    this.playerReady = true;
  }

  setCurrentTime(currentTime) {
    this.currentTime = currentTime;
  }

  play(currentTime) {
    if (!this.playerReady) {
      return;
    }
    // if currentTime is 0 or null, then check if we have a stored currentTime
    currentTime = currentTime || this.currentTime;
    console.log("Youtube video was played");
    this.player.seekTo(currentTime);
    this.player.playVideo();
  }

  stop() {
    return new Promise((resolve) => {
      console.log("Youtube video was stopped");
      this.player.pauseVideo();
      const currentTime = this.player.getCurrentTime();
      this.setCurrentTime(currentTime);

      resolve(currentTime);
    });
  }
}

export default YoutubePlayer;
